* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "poppins", sans-serif;
}

html,
body {
  background-color: #f1f1f1;
  font-family: "poppins", sans-serif;
  height: 100%;
}

#root {
    height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.App.Homepage {
  background-color: #f1f1f1;
  background-image: url(https://res.cloudinary.com/kadet/image/upload/v1626252732/brooke-cagle-g1Kr4Ozfoac-unsplash_bn4adg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 2000px 2000px #000000d8;
}

.container {
  flex-grow: 1;
  padding: 1rem 4%;
}
